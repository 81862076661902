import React, { useEffect, useState } from 'react'
import Toast from '../../components/Toast'
import { PREFERRED_SERIES_TOAST_VIEWED, preferredOverQueryKey } from '../../common/preferredSeries'
import { useContentType, usePreferredSeriesHrefTemplate, usePreferredSeriesToastViewed } from './hooks/seriesHooks'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useGetAndRemoveQuery } from './hooks/useGetAndRemoveQuery'

/**
 * This is a toast component showing on specific redirects and series/podcasts with preferred link.
 * @returns {JSX.Element|null} Component?
 */
const PreferredSeriesToast = () => {
  const [isHidden, setIsHidden] = useState(false)
  const redirectedFromPath = useGetAndRemoveQuery(preferredOverQueryKey)
  const location = useLocation()
  const preferredSeriesPath = usePreferredSeriesHrefTemplate()
  const preferredSeriesToastIsViewed = usePreferredSeriesToastViewed()
  const dispatch = useDispatch()
  const contentType = useContentType()
  const isPodcast = contentType === 'podcast'

  // Set to viewed when unMounting toast to not show on multiple pages
  useEffect(() => {
    return () => {
      dispatch({ type: PREFERRED_SERIES_TOAST_VIEWED })
    }
  }, [dispatch])

  if (preferredSeriesToastIsViewed) return null

  if (redirectedFromPath) {
    return (
      <Toast isHidden={isHidden} setIsHidden={setIsHidden} duration={10000} iconId="nrk-info">
        {`Du ble tatt automatisk til ${isPodcast ? 'podkastversjonen' : 'radioversjonen'}. `}
        <Link
          to={{ pathname: redirectedFromPath, prevPathname: location.pathname }}
          style={{ textDecoration: 'underline' }}
        >
          Trykk her
        </Link>
        {` for å åpne ${isPodcast ? 'radioversjonen' : 'podkastversjonen'} likevel.`}
      </Toast>
    )
  }

  if (preferredSeriesPath) {
    return (
      <Toast isHidden={isHidden} setIsHidden={setIsHidden} duration={10000} iconId="nrk-info">
        {`Det finnes en ${isPodcast ? 'radioversjonen' : 'podkastversjon'} av dette programmet. `}
        <Link
          to={{ pathname: preferredSeriesPath, prevPathname: location.pathname }}
          style={{ textDecoration: 'underline' }}
        >
          Trykk her
        </Link>
        {' for å åpne den!'}
      </Toast>
    )
  }
}

export default PreferredSeriesToast
