import React from 'react'

import SeriesPage from './SeriesPage'
import { useSeriesType } from './hooks/seriesHooks'
import SERIES_TYPES from './helpers/seriesTypes'
import SeriesUmbrellaPage from './SeriesUmbrellaPage'
import { withSeriesMeta } from './SeriesMeta'
import PreferredSeriesToast from './PreferredSeriesToast'

const Series = () => {
  const seriesType = useSeriesType()

  switch (seriesType) {
    case SERIES_TYPES.UMBRELLA:
      return <SeriesUmbrellaPage />
    case SERIES_TYPES.STANDARD:
    case SERIES_TYPES.SEQUENTIAL:
      return <SeriesPage />
    default:
      return <SeriesPage />
  }
}

Series.propTypes = {}

const SeriesWithPreferredSeriesToast = () => (
  <>
    <PreferredSeriesToast />
    <Series />
  </>
)

export default withSeriesMeta(SeriesWithPreferredSeriesToast)
