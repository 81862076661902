import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

/**
 * Gets the query value of the of the `queryKey` specifyed and removes it from the url.
 * @param {string} queryKey
 * @returns {string} Query value
 */
export const useGetAndRemoveQuery = queryKey => {
  const location = useLocation()
  const history = useHistory()
  const [queryValue, setQueryValue] = useState('')

  useEffect(() => {
    if (!queryValue) {
      const query = new URLSearchParams(location.search)
      const value = query.get(queryKey)
      if (value) {
        setQueryValue(value)
        query.delete(queryKey)
        if (query.size > 0) {
          history.replace(`${location.pathname}?${query}`)
        } else {
          history.replace(location.pathname)
        }
      }
    }
  }, [queryValue, setQueryValue, queryKey, history, location.search, location.pathname])

  return queryValue
}
